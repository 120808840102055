<template>
  <div>
    <div v-if="lightBoxData.length > 0">
      <LightBox
        ref="lightbox"
        :media="lightBoxData"
        :show-thumbs="play"
        :length-to-load-more="lightBoxData.length"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        @onClosed="onClosedEvent"
      />
    </div>
    <layout-vertical>

      <router-view />

      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />

      </template>

    </layout-vertical>
  </div>
</template>

<script>
// import { $themeConfig } from '@themeConfig'

import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import LightBox from 'vue-it-bigger'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    LightBox,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      play: false,
    }
  },
  computed: {
    lightBoxData() {
      return this.$store.getters['lightBox/getLightBoxData']
    },
  },
  watch: {
    lightBoxData() {
      if (this.lightBoxData) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },
  },
  methods: {
    hideLightBox() {
      this.$store.dispatch('lightBox/closeLightBox')
    },
    onClosedEvent() {
      this.hideLightBox()
    },
  },
}
</script>
